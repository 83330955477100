<template>
    <section class="w-full md:w-10 m-auto mt-3 p-6 bg-gray-200" style="max-width: 900px">
        <div class="flex justify-content-between pb-4" style="border-bottom: 2px solid #0000001a">
            <h1>Detalhes do Pedido</h1>
        </div>
        <div class="mt-4" style="width: 96%">
            <div class="grid col-12 p-0">
                <div class="col-12 md:col-4">
                    <label class="mb-2" for="">Comprador</label>
                    <p class="mt-2">{{ checkout?.nome }}</p>
                </div>
                <div class="col-12 md:col-3">
                    <label class="mb-2" for="">Cpf</label>
                    <p class="mt-2">{{ $toCpf(checkout?.cpf) }}</p>
                </div>
                <div class="col-12 md:col-3">
                    <label class="mb-2" for="">E-mail</label>
                    <p class="mt-2">{{ checkout?.emails }}</p>
                </div>
            </div>
            <div class="grid col-12 p-0">
                <div class="col-12 md:col-4">
                    <label class="mb-2" for="">Quem cadastrou</label>
                    <p class="mt-2">{{ checkout?.nomeUsuarioCadastro }}</p>
                </div>
                <div class="col-12 md:col-3">
                    <label class="mb-2" for="">Data do cadastro</label>
                    <p class="mt-2">{{ checkout?.dataCadastroFormatada }}</p>
                </div>
                <div class="col-12 md:col-3">
                    <label for="">Situação</label>
                    <p class="mt-2">{{ checkout?.situacaoDescricao }}</p>
                </div>
            </div>
        </div>
        <div class="mt-2" style="border-top: 2px solid #0000001a">
            <div class="mt-4">
                <h5 class="m-0 p-0">Detalhes do Produto</h5>
            </div>
            <div class="grid col-12">
                <div class="col-12 md:col-4">
                    <label class="mb-2" for="">Itens</label>
                    <p class="mt-2">{{ checkout?.produto?.titulo }}</p>
                    <p class="mt-2">{{ checkout?.produto?.descricao }}</p>
                </div>
                <div class="col-12 md:col-3">
                    <label class="mb-2" for="">Data inicial</label>
                    <p class="mt-2">{{ $dateFormat(checkout?.produto?.dataInicial, 'DD/MM/YYYY') }}</p>
                </div>
                <div class="col-12 md:col-3">
                    <label class="mb-2" for="">Data final</label>
                    <p class="mt-2">{{ $dateFormat(checkout?.produto?.dataFinal, 'DD/MM/YYYY') }}</p>
                </div>
                <div class="col-12 md:col-3">
                    <label class="mb-2" for="">Valor</label>
                    <p class="mt-2">{{ checkout?.produto?.valorFormatado }}</p>
                </div>
            </div>
        </div>
        <div class="border-2 border-black-alpha-10 p-2 md:p-5 mt-3" style="border-radius: 16px">
            <h5>Resumo do checkout</h5>
            <div>
                <div class="flex justify-content-between">
                    <label for="">Subtotal do(s) item(ns)</label>
                    <p>{{ checkout?.produto?.valorFormatado }}</p>
                </div>
                <div class="flex justify-content-between mt-1 mb-1">
                    <label for="">Desconto</label>
                    <p>{{ checkout?.desconto || '-' }}</p>
                </div>
                <div class="flex justify-content-between">
                    <label class="font-semibold" for="">Total</label>
                    <p class="font-semibold">{{ checkout?.produto?.valorFormatado }}</p>
                </div>
            </div>
            <div class="mt-3" style="border-top: 1px solid #0000001a">
                <div class="flex justify-content-between mt-3">
                    <p>A forma de pagamento escolhida foi:</p>
                    <p class="text-xl font-semibold">{{ this.formaPagamento() }}</p>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end mt-3">
            <Button label="Voltar" @click="$router.go(-1)">Voltar</Button>
        </div>
    </section>
</template>

<script>
import service from './service';
export default {
    data() {
        return {
            checkout: [],
            error: null,
        };
    },

    emits: ['check'],

    mounted() {
        this.buscaCheckoutId();
    },

    methods: {
        formaPagamento() {
            if (this.checkout.tipoPagamento == 1) {
                if (this.checkout.qtdParcela > 1) {
                    return `${this.checkout.tipoPagamentoDescricao} ${this.checkout.qtdParcela} Parcelas`;
                } else {
                    return `${this.checkout.tipoPagamentoDescricao} ${this.checkout.qtdParcela} Parcela`;
                }
            }
            return this.checkout?.tipoPagamentoDescricao || '-';
        },
        buscaCheckoutId() {
            this.$store.dispatch('addRequest');
            service
                .obterCheckoutId(this.$route.params.id)
                .then((response) => {
                    this.checkout = response.data;
                })
                .catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Erro ao buscar detalhes do pedido',
                        life: 3000,
                    });
                    this.error = error;
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>

<style scoped>
h1,
h2,
h3,
p {
    margin: 0;
    padding: 0;
}
p {
    color: #424242;
}
label {
    color: #6c757d;
}
th {
    width: 100%;
}
th:first-child,
td:first-child {
    width: 70%; /* Define a largura da primeira coluna */
}
th:nth-child(2),
td:nth-child(2),
th:nth-child(3),
td:nth-child(3) {
    width: 15%; /* Define a largura das outras colunas */
    text-align: center;
    align-content: flex-start;
    padding-top: 8px;
    color: #424242;
}

.card-orientacoes {
    background-color: #d8e0e2;
    padding: 2rem;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
}
</style>
